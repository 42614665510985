import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/workspace/src/components/layouts/MDXBlogPostLayout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const MyVideo = makeShortcode("MyVideo");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`So, you just finished implementing a new feature and this of course comes with many changes in the codebase.`}</p>
    <p>{`Are your existing documentation notes still accurate?`}</p>
    <p>{`As soon as the content of a Code View has changed, the Issues Panel lets you know and allows you to quickly navigate to the next issue:`}</p>
    <MyVideo src="https://s3.fr-par.scw.cloud/codestory-web/media/IssuesPanel.mp4" mdxType="MyVideo" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      